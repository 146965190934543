import { registerHeaderUserAction } from '@cencosud/ui/src/v2/compound/navbar/NavBarAnalyticsEvents';
export var menuOptionNoLogged = [{
  id: 1,
  name: 'Mi Cupón',
  url: '/mispromociones/promociones',
  analytics: function analytics() {
    return registerHeaderUserAction('Mi Cupón');
  }
}, {
  id: 2,
  name: 'Puntos Extra',
  url: 'https://www.puntosatupinta.cl/puntos_extra/',
  analytics: function analytics() {
    return registerHeaderUserAction('Puntos Extra');
  }
}, {
  id: 3,
  name: 'Conoce Puntos Cencosud',
  url: '/faq',
  analytics: function analytics() {
    return registerHeaderUserAction('Conoce Puntos Cencosud');
  }
}];
export var menuCentroDeAyuda = [{
  id: 4,
  name: 'Centro de ayuda',
  url: 'https://ayuda.puntoscencosud.cl/',
  analytics: function analytics() {
    return registerHeaderUserAction('Centro de Ayuda');
  }
}];
export var menuOptionLogged = function menuOptionLogged(setModalChangePasswordVisible, handleLogout) {
  return [{
    id: 0,
    name: 'Mi Cupón',
    url: '/mispromociones/promociones',
    analytics: function analytics() {
      return registerHeaderUserAction('Mi Cupón');
    }
  }, {
    id: 1,
    name: 'Estado de cuenta',
    url: '/cuenta'
  }, {
    id: 2,
    name: 'Puntos por vencer',
    url: '/puntos-por-vencer'
  }, {
    id: 3,
    name: 'Mis canjes',
    url: '/mis-canjes'
  }, {
    id: 4,
    name: 'Mis datos',
    url: '/perfil'
  }, {
    id: 5,
    name: 'Administrar adicionales',
    url: '/adicionales'
  }, {
    id: 6,
    name: 'Cambiar contraseña',
    function: function _function() {
      return setModalChangePasswordVisible(true);
    }
  }, {
    id: 7,
    name: 'Cerrar sesión',
    function: function _function() {
      return handleLogout();
    },
    custonCss: 'logout-border'
  }];
};