import _defineProperty from "/home/jenkins/agent/workspace/UCTION_FRONT_deploy_front_master/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import isObject from 'lodash/isObject';
var normalizeBanner = function normalizeBanner(x) {
  var _x$webImage, _x$mobileImage;
  return {
    accessibilityLabel: x.name,
    source: (_x$webImage = x.webImage) === null || _x$webImage === void 0 ? void 0 : _x$webImage.url,
    responsive: (_x$mobileImage = x.mobileImage) === null || _x$mobileImage === void 0 ? void 0 : _x$mobileImage.url,
    screen: x.link,
    link: x.externalLink,
    targetLink: x.targetLink,
    id: x.id
  };
};
var bindBasicCategoriesParameter = function bindBasicCategoriesParameter(category) {
  return {
    banner: isObject(category.banner) ? normalizeBanner(category.banner) : normalizeBanner({}),
    id: category.id,
    nombre: category.name,
    partnerId: category.partnerDomain,
    orden: category.order,
    exchangeStorePDF: category.exchangeStorePDF,
    categoryIcon: category.categoryIcon,
    insideColorBadge: category.insideColorBadge,
    exteriorColorBadge: category.exteriorColorBadge,
    description: category.description,
    hideViewAll: category.hideViewAll
  };
};
var _bindSubCategory = function bindSubCategory(subCategory) {
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, bindBasicCategoriesParameter(subCategory)), {
    subcategorias: subCategory.subcategories ? subCategory.subcategories.map(function (subCategory) {
      return _bindSubCategory(subCategory);
    }) : []
  }), {
    nombreCategoria: subCategory.parentCategory.name
  }), {
    nombreSubCategoria: subCategory.name
  });
};
export var bindCategory = function bindCategory(category) {
  return _objectSpread(_objectSpread({}, {
    subcategorias: category.subcategories ? category.subcategories.map(function (subCategory) {
      return _bindSubCategory(subCategory);
    }) : []
  }), bindBasicCategoriesParameter(category));
};
export default (function (categories) {
  var _categories$payload;
  return (_categories$payload = categories.payload) === null || _categories$payload === void 0 ? void 0 : _categories$payload.map(function (category) {
    return !isObject(category) ? {
      subcategorias: []
    } : bindCategory(category);
  });
});