export default {
  apisExternas: {
    googleMaps: {
      key: 'AIzaSyBEyNhi1CrHEOVAgWUwGTQY2OENAN7HtR8'
    },
    googleRecaptcha: {
      invisible: '6Lfk6FoaAAAAAHwQAaw85zuoptTHNPMUbTua-I0n',
      visible: '6LejYZ4UAAAAAEPK_vPNXNanuG6h5LsD3OZN2h42'
    },
    googleRecaptchav3: {
      key: '6Ldrc9oUAAAAAGj1laBwxRr7656q-uZSW-lud2G2',
      action: 'puntos_LoginForm'
    }
  },
  api: {
    publicEndpoint: '/public',
    privateEndpoint: '/private',
    key: 'CM7CJ8wMZoyAV03w5jZEnAQtF5Ju6WuC',
    cloudKey: '5udeIxHYNI23mu3jtYNFC5MxkFzMe0zPYcGuFXu4',
    kongDomain: 'api.cencosud.cl',
    cloudDomain: 'fidelidad.cencosud.com',
    identityType: {
      partner: 'puntos-cencosud-socios-partners-prod',
      default: 'puntos-cencosud-prod'
    },
    consumers: {
      android: 'fi_cl_mobile',
      ios: 'fi_cl_mobile',
      web: 'fi_cl_puntoscl'
    },
    list: {
      auth: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/security/public/login/socios',
        kongId: undefined,
        kongName: undefined
      },
      content: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v2/content',
        kongId: undefined,
        kongName: undefined
      },
      contentV3: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/cms',
        kongId: undefined,
        kongName: undefined
      },
      log: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/puntos/log',
        kongId: '97ce98ca-a4bf-4dc7-9cd8-2d9ed35083ad',
        kongName: 'fi.cl.puntos.log.public'
      },
      loyalty: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/loyalty',
        kongId: '7fbe4718-f789-4d1b-a85b-2f35ac6aad8b',
        kongName: 'fi.cl.puntos.loyalty.private'
      },
      security: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/security',
        kongId: '81518991-7d2a-4d40-8ffa-1a50d1707132',
        kongName: 'fi.cl.puntos.loyalty.security.private'
      },
      ecommerce: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/puntos/ecommerce',
        kongId: undefined,
        kongName: undefined
      },
      ofertas: {
        url: 'https://fidelidad.cencosud.com/fi/cl/v1/loyalty/ofertas',
        kongId: undefined,
        kongName: undefined
      },
      rss: {
        url: 'https://puntoscencosud.cl/rss/puntos',
        kongId: undefined,
        kongName: undefined
      }
    }
  },
  sesion: {
    inactividad: {
      /*
      tiempo en milisegundos, cualquier valor menor a 1 inhabilita la característica de
      cierre de sesión por inactividad.
       */
      tiempoAntesNotificacion: 60000 * 4.5,
      // tiempo en milisegundos
      tiempoCuentaRegresiva: 30000
    }
  }
};