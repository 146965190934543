import persistentStore from '../../../../../state/persistentStore';
var configInicial = {
  appMobile: 0,
  autoopen: false,
  id_servicio: '28',
  mostrar_widget: 1,
  url: 'https://chatcenter.entelcc.cl'
};
var getClient = function getClient() {
  var _rut = localStorage.getItem('rut');
  var rut = _rut === 'null' ? null : _rut;
  var nombre = localStorage.getItem('puntosName');
  var correo = localStorage.getItem('puntosCorreo');
  var telefono = localStorage.getItem('puntosTlf');
  var client = {
    rut: rut,
    nombre: nombre,
    correo: correo,
    telefono: telefono,
    emptyClient: !nombre && !correo && !telefono && !rut
  };
  return client;
};
var init = function init() {
  try {
    var _CC;
    var client = getClient();
    persistentStore.clearChatCenter();
    // eslint-disable-next-line
    (_CC = CC) === null || _CC === void 0 ? void 0 : _CC.BuildWidget(true, client.nombre, client.rut, client.telefono, client.correo, function () {
      // HOME-V3
      if (client.emptyClient) {
        sessionStorage.setItem('configApp', JSON.stringify(configInicial));
      }
    });
  } catch (e) {
    console.log('ERROR CHAT');
  }
};
var updateConfig = function updateConfig() {
  var _CCWidget;
  var client = getClient();
  // eslint-disable-next-line
  (_CCWidget = CCWidget) === null || _CCWidget === void 0 ? void 0 : _CCWidget.UpdateConfigInit(client.nombre, client.rut, client.telefono, client.correo);
};
var cleanConfig = function cleanConfig() {
  var _CCWidget2;
  // eslint-disable-next-line
  (_CCWidget2 = CCWidget) === null || _CCWidget2 === void 0 ? void 0 : _CCWidget2.UpdateConfigInit(null, null, null, null);
};
var openChat = function openChat() {
  try {
    var _CCWidget3;
    // eslint-disable-next-line
    (_CCWidget3 = CCWidget) === null || _CCWidget3 === void 0 ? void 0 : _CCWidget3.AutoOpen();
  } catch (e) {
    // eslint-disable-next-line
    console.log('ERROR CHAT');
  }
};
export { init, openChat, updateConfig, cleanConfig };