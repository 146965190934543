var homeBody = {
  result: {
    message: 'OK'
  },
  payload: {
    id: null,
    carrusel: [{
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: null
    }, {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: 'https://puntoscencosudfdlt.cencosud.corp/puntos/categorias/estadias'
    }, {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: '/categorias/panoramas/entretenimiento'
    }, {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: 'https://g500603sv04b:4443/puntos/categorias/servicios'
    }, {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: 'www.paris.cl'
    }],
    banner1: {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: null
    },
    banner2: null,
    banner3: {
      id: null,
      altText: 'Banner diseño V2',
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: 'http://www.google.cl'
    },
    banner4: {
      id: null,
      altText: null,
      landing: null,
      urlImagen: './images/mock-1200x400.jpg',
      urlImagenMobile: './images/mock-1200x400.jpg',
      link: null
    },
    canjeTienda: [{
      altText: 'Whisky Johnnie Walker Black Label',
      id: '1534178800274',
      urlImagen: './images/mock-401x476.jpg',
      nombre: 'Whisky Johnnie Walker Black Label',
      puntos: 0,
      tagNuevo: false,
      tagOferta: false,
      stock: 'null',
      icono: null,
      tienda: 'Jumbo',
      cantidadProductos: null,
      sku: null
    }, {
      altText: 'Batería 7 piezas',
      id: '1534178811547',
      urlImagen: './images/mock-401x476.jpg',
      nombre: 'Batería 7 piezas',
      puntos: 6000,
      tagNuevo: false,
      tagOferta: false,
      stock: 'null',
      icono: null,
      tienda: 'Paris',
      cantidadProductos: null,
      sku: null
    }, {
      altText: 'Calienta Cama 1P',
      id: '1534178811623',
      urlImagen: './images/mock-401x476.jpg',
      nombre: 'Calienta Cama 1P',
      puntos: 4000,
      tagNuevo: false,
      tagOferta: false,
      stock: 'null',
      icono: null,
      tienda: 'Paris',
      cantidadProductos: null,
      sku: null
    }],
    canjeOnline: [{
      altText: 'Boligrafo',
      tagHome: null,
      id: '1558479131686',
      nombreCategoria: null,
      urlImagen: './images/mock-401x476.jpg',
      nombre: 'TestProduct',
      puntos: 1000,
      tagNuevo: false,
      tagOferta: false,
      stock: 'Agotado',
      icono: null
    }, {
      altText: 'texto alternativo macbook pro',
      tagHome: null,
      id: '1528751946927',
      nombreCategoria: null,
      urlImagen: './images/mock-401x476.jpg',
      nombre: 'Macbook Pro',
      puntos: 9999,
      tagNuevo: false,
      tagOferta: false,
      stock: 'Agotado',
      icono: null
    }],
    popup: null
  }
};
export default homeBody;